<template>
  <footer>
    <div class="container">
      <div class="contact-us">
        <p><small>©2024, DFCD INTERIOR DESIGN</small></p>
        <!-- <a href="mailto:123@gmail.com">
          <img :src="require('@/assets/images/about/email.png')" style="width: 36px;"/>
        </a> -->
      </div>
    </div>
  </footer>
</template>


<script>
export default {
  name: 'DFCDFooter',
  components: {
  },
  data() {
    return {
    }
  },
  props: {
  },
  created() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
  }

}
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/basic";
  
  footer {
    // background-color: #fff;
    padding: 1.5rem 0;
    @include smaller-than-medium {
      padding: 1rem 0;
    }
    .contact-us {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      p {
        margin: 0;
      }
      @include smaller-than-medium {
        justify-content: center;
      }

    }
  }
</style>
