<template>
  <nav :class="{'opened': maskShow}">
    <router-link :to="{name: 'Home'}" class="logo">
      <img :src="require('@/assets/images/DFCD_logo.svg')" />
    </router-link>

    <button class="menu-btn" @click="menuBtnClicked">
      <svg xmlns="http://www.w3.org/2000/svg" width="36" height="19" viewBox="0 0 36 19" fill="none">
        <line y1="0.5" x2="36" y2="0.5" stroke="black"/>
        <line y1="9.5" x2="36" y2="9.5" stroke="black"/>
        <line y1="18.5" x2="36" y2="18.5" stroke="black"/>
      </svg>
    </button>

    <Transition name="fade">
      <div class="fs-mask" v-if="maskShow" @click="maskShow = false">
        <ul>
          <li>
            <router-link :to="{name: 'About'}">About</router-link>
          </li>
          <li>
            <router-link :to="{name: 'PortfolioIndex'}">Portfolio</router-link>
          </li>
          <li>
            <router-link :to="{name: 'Service'}">Service</router-link>
          </li>
          <li>
            <router-link :to="{name: 'Contact'}">Contact</router-link>
          </li>
        </ul>
      </div>
    </Transition>
  </nav>
</template>

<script>
export default {
  name: 'DFCDNav',
  components: {
  },
  data() {
    return {
      maskShow: false,
      windowSize: {
        w: 0,
        h: 0,
      },
      mobileMaxWidth: 768,
      mobileMenuShow: false,
    }
  },
  props: {
  },
  created() {
    this.windowSize.w = window.innerWidth;
    this.windowSize.h = window.innerHeight;
    window.addEventListener('resize', () => {
      this.$set(this.windowSize, 'w', window.innerWidth);
      this.$set(this.windowSize, 'h', window.innerHeight);
      // this.windowSize.w = window.innerWidth;
      // this.windowSize.h = window.innerHeight;
    });
  },
  mounted() {
  },
  beforeDestroy() {
  },
  computed: {
    menuShow() {
      if (this.windowSize.w > this.mobileMaxWidth) {
        return this.$store.getters.isNavShow;
      }
      return this.mobileMenuShow;
    },
  },
  watch: {
  },
  methods: {
    menuBtnClicked() {
      this.maskShow = !this.maskShow;
    }
  }

}
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/basic";

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  .fade-enter-to, .fade-leave {
    opacity: 1;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .4s ease;
  }

  nav {
    position: fixed;
    width: 100%;
    height: 0;
    top: 0;
    left: 0;
    z-index: 100;
    .logo {
      position: absolute;
      left: 1rem;
      top: 0;
      img {
        width: 10rem;
        @include smaller-than-medium {
          width: 6rem;
        }
      }
    }
    .menu-btn {
      background-color: transparent;
      border: none;

      position: absolute;
      right: 0rem;
      top: 0;
      padding: 1rem;
      svg {
        width: 2rem;
        * {
          transition: all .4s ease;
        }
      }
    }
  }

  .opened {
    svg {
      position: relative;
      z-index: 100000;
      line {
        stroke: #fff;
      }
      line:first-child {
        transform: translate(45%, 0) rotateZ(45deg);
      }

      line:nth-child(2) {
        opacity: 0;
      }

      line:last-child {
        transform: translate(0%, 50%) rotateZ(-45deg);
      }
    }
  }

  .fs-mask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background-color: #0009;
    ul {
      padding: 4rem 1rem 0 1rem;
      margin: 0;
      list-style: none;
      li {
        padding: 0;
        margin: 0;
        margin-bottom: 1rem;
        text-align: right;
        a {
          color: #fff;
          font-size: 1.125rem;
          transition: all .4s ease;
          position: relative;
          &:after {
            content: '';
            position: absolute;
            width: 0;
            bottom: 0;
            left: 0;
            transition: all .4s ease;
            border-bottom: solid 2px #fff;
          }
          &:hover {
            color: $color-link-black;
            text-decoration: none;
            &:after {
              width: 100%;
              border-color: $color-link-black;
            }
          }
        }
      }
    }
  }

</style>