<template>
  <div class="home">
    <section class="banner">
      <div class="banner-container">
        <div class="banner-img"></div>
        <img class="logo" :src="require('@/assets/images/DFCD_logo.svg')"/>
        <!-- <img :src="require('@/assets/images/indexBanner.png')"/> -->
      </div>
      <nav class="banner-nav">
        <ul>
          <li>
            <router-link :to="{name:'About'}">About</router-link>
          </li>
          <li>
            <router-link :to="{name:'PortfolioIndex'}">Portfolio</router-link>
          </li>
          <li>
            <router-link :to="{name:'Service'}">Service</router-link>
          </li>
          <li>
            <router-link :to="{name:'Contact'}">Contact</router-link>
          </li>
        </ul>
      </nav>
    </section>

    <section class="portfolio">
      <div class="container">
        <div class="portfolio-list">
          <div class="portfolio-list-col" v-for="(item, index) in portfolios" :key="index">
            <Scrollama @step-enter="stepEnterHandler">
              <router-link :to="item.link" class="portfolio-card">
                <div class="p-img">
                  <div class="img-container">
                    <img :src="item.img" />
                  </div>
                </div>

                <div class="p-text">
                  <p><span>{{ item.name }}</span></p>
                </div>
              </router-link>
            </Scrollama>
          </div>
        </div>
      </div>
    </section>

    <section class="awards px-4 px-lg-0">
      <div class="container">
        <h2>獎項 awards</h2>
        <p>美國MUSE AWARDS 2023</p>
        <p>義大利 A’DESIGN AWARDS 2023</p>
        <p>iF DESIGN AWARD 2024</p>
        <p>BERLIN DESIGN AWARDS 2024</p>
        <p>中國國際空間設計大賽</p>
        <div class="awards-list">
          <a href="https://design.museaward.com/winner-info.php?id=15038" target="_blank">
            <img :src="require('@/assets/images/index/award01.png')"/>
          </a>
          <a href="https://competition.adesignaward.com/design.php?ID=151072" target="_blank">
            <img :src="require('@/assets/images/index/award02.png')"/>
          </a>
          <a href="https://betterfutureawards.com/ber24" target="_blank">
            <img :src="require('@/assets/images/index/award04.jpg')"/>
          </a>
          <a href="https://ifdesign.com/" class="if-award" target="_blank">
            <img :src="require('@/assets/images/index/award03.png')"/>
          </a>
        </div>
      </div>
    </section>
    
    <DFCDFooter></DFCDFooter>
  </div>
</template>

<script>
import 'intersection-observer' // for cross-browser support
import Scrollama from 'vue-scrollama' // local registration in this example, can also be globally registered
// @ is an alias to /src
import DFCDFooter from '@/components/DFCDFooter.vue';
import p01 from '@/assets/images/p01/1.png';
import p02 from '@/assets/images/p02/1.png';
import p03 from '@/assets/images/p03/1.png';
import p04 from '@/assets/images/p04/1.png';

export default {
  name: 'Home',
  data() {
    return {
      portfolios: [
        {
          name: '林口 · 顏宅',
          link: {
            name: 'Portfolio1',
          },
          img: p01,
        },
        {
          name: '林口 · 蔣宅',
          link: {
            name: 'Portfolio2',
          },
          img: p02,
        },
        {
          name: '新莊 · 永鼎方宅',
          link: {
            name: 'Portfolio3',
          },
          img: p03,
        },
        {
          name: '新莊 · Serenity',
          link: {
            name: 'Portfolio4',
          },
          img: p04,
        },
      ],
    };
  },
  components: {
    DFCDFooter,
    Scrollama,
  },
  methods: {
    stepEnterHandler ({element}) {
      element.classList.add('active')
      // handle the step-event as required here
      // console.log({ element, index, direction });
      // use the data attributes if needed
      // console.log(element.dataset.step) // a, b or c 
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/basic";
  .home {
  }

  .banner {
    position: relative;
    width: 100%;
    height: 100dvh;
    height: 100vh;
    overflow: hidden;
    .banner-container {
      position: relative;
      width: 100%;
      height: 100%;
      .banner-img {
        width: 105%;
        height: 100%;
        background-image: url('~@/assets/images/indexBanner.png');
        background-size: cover;
        background-position: bottom center;
        background-repeat: no-repeat;
  
        animation-name: banner-img;
        animation-iteration-count: infinite;
        animation-duration: 20s;
        animation-timing-function: ease-in-out;
        animation-fill-mode: both;
        animation-direction: alternate;
      }
  
      @keyframes banner-img {
        0% {
          transform: translate(-4.7%, 0);
        }
        100% {
          transform: translate(0, 0);
        }
      }

      @keyframes banner-img-2 {
        0% {
          transform: translate(-17.0%, 0);
        }
        100% {
          transform: translate(0, 0);
        }
      }
  
      .logo {
        position: absolute;
        top: 0;
        left: 1.6rem;
        width: 10rem;
        @include smaller-than-medium {
          width: 6rem;
        }
      }
      
      @include smaller-than-medium {
        .banner-img {
          width: 120%;
    
          animation-name: banner-img-2;
          animation-iteration-count: infinite;
          animation-duration: 20s;
          animation-timing-function: ease-in-out;
          animation-fill-mode: both;
          animation-direction: alternate;
        }
      }
      
    }
    .banner-nav {
      position: absolute;
      top: 7rem;
      right: 2.75rem;
      @include smaller-than-medium {
        top: initial;
        bottom: 5rem;
        right: 1.6rem;
      }
      ul {
        padding: 0;
        margin: 0;
        list-style: none;
        li {
          padding: 0;
          margin: 0;
          text-align: right;
          margin-bottom: 1rem;
          a {
            color: #fff;
            font-size: 1.125rem;
            transition: all .4s ease;
            position: relative;
            &:after {
              content: '';
              position: absolute;
              width: 0;
              bottom: 0;
              left: 0;
              transition: all .4s ease;
              border-bottom: solid 2px #fff;
            }
            &:hover {
              color: $color-link-black;
              text-decoration: none;
              &:after {
                width: 100%;
                border-color: $color-link-black;
              }
            }
          }
        }
      }
    }
  }

  .portfolio {
    padding-top: 14rem;
    padding-bottom: 4rem;
    @include smaller-than-medium {
      padding-top: 4rem;
    }
    .portfolio-list {
      @include smaller-than-medium {
        margin-left: -15px;
        margin-right: -15px;
      }
      .portfolio-list-col {
        .portfolio-card {
          position: relative;
          width: 100%;
          max-width: 100%;
          display: flex;
          flex-wrap: wrap;
          align-items: flex-end;
          color: $color-link-black;
          &:hover {
            text-decoration: none;
          }
          .p-img {
            flex: 0 0 65%;
            position: relative;
            .img-container {
              position: relative;
              width: 100%;
              padding-top: 66%;
              transition: all .8s ease;
              transform: translate(0, 25%);
              opacity: 0;
              img {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                object-fit: cover;
              }
            }
          }
          .p-text {
            flex: 0 0 35%;
            padding-left: 3rem;
            overflow: hidden;
            p {
              position: relative;
              text-align: right;
              padding-bottom: .5rem;
              margin-bottom: 0;
              span {
                display: inline-block;
                transition: all .8s .8s ease;
                transform: translate(0, 120%);
              }
              &:after {
                content: '';
                width: 0;
                transition: all .8s ease;
                position: absolute;
                bottom: 0;
                left: 0;
                border-bottom: solid 1px $color-link-black;
              }
            }
          }

          @include smaller-than-medium {
            .p-img {
              flex: 0 0 100%;
              order: 2;
              margin-top: 1.5rem;
              .img-container {
                padding-top: 90%;
              }
            }
            .p-text {
              flex: 0 0 100%;
              order: 1;
              padding-left: 0;
              padding-right: 45%;
              p {
                padding-bottom: 0rem;
              }
            }
          }

          &.active {
            .img-container {
              transform: translate(0, 0);
              opacity: 1;
            }

            .p-text {
              p {
                span{
                  transform: translate(0, 0);
                }
                &:after {
                  width: 100%;
                }
              }
            }
          }
        }

        &:not(:first-child) {
          margin-top: 8rem;
          @include smaller-than-medium {
            margin-top: 3rem;
          }
        }
      }
    }
  }

  .awards {
    h2 {
      font-size: 1.25rem;
    }
    
    p {
      margin-bottom: .5rem;
    }

    .awards-list {
      display: flex;
      column-gap: 1rem;
      row-gap: 2rem;
      margin-top: 2rem;
      flex-wrap: wrap;
      align-items: center;
      img {
        height: 6rem;
      }
      a {
        display: block;
        &.if-award {
          img {
            height: 5rem;
            @include smaller-than-medium {
              height: 4rem;
            }
          }
        }
      }
      @include smaller-than-medium {
        column-gap: 1.5rem;
        margin-top: 2rem;
        margin-bottom: 4rem;
      }
    }
  }

</style>