import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    showNav: true,
  },
  getters: {
    isNavShow(state) {
      return state.showNav;
    },
  },
  mutations: {
    SETNAVSHOWMUTATION(state, payload) {
      state.showNav = payload;
    }
  },
  actions: {
    setNavShow(context, show) {
      context.commit("SETNAVSHOWMUTATION", show);
    },
  },
  modules: {
  }
})
