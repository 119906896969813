<template>
  <div id="app">
    <Transition name="fade">
      <router-view/>
    </Transition>

    <DFCDNav v-if="!hideNav"></DFCDNav>
    <Transition name="slow-fade">
      <OpeningModal v-if="loaded"></OpeningModal>
    </Transition>
  </div>
</template>

<script>
import { BootstrapVue } from 'bootstrap-vue';
import Vue from 'vue';
Vue.use(BootstrapVue);

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import DFCDNav from '@/components/DFCDNav.vue';
import OpeningModal from '@/components/OpeningModal';

export default {
  name: 'App',
  components: {
    DFCDNav,
    OpeningModal,
  },
  data() {
    return {
      loaded: true,
    }
  },
  props: {
  },
  created() {
    setTimeout(() => {
      this.loaded = false;
    }, 3000);
  },
  mounted() {
  },
  beforeDestroy() {
  },
  computed: {
    hideNav() {
      if (this.$route.meta.hideNav) {
        return true;
      }
      return false;
    }
  },
  watch: {
  },
  methods: {
  }

}
</script>

<style>
  html, body {
    
  }

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-button {
    background: transparent;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-track-piece {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.4);
    border: 1px solid slategrey;
  }

  ::-webkit-scrollbar-track {
    box-shadow: transparent;
  }
</style>

<style scoped>
  #app {
    
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .4s ease;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  .fade-enter-to, .fade-leave {
    opacity: 1;
  }

  .slow-fade-enter-to, .slow-fade-leave {
    opacity: 1;
  }

  .slow-fade-enter-active, .slow-fade-leave-active {
    transition: opacity 1.5s ease;
  }

  .slow-fade-enter, .slow-fade-leave-to {
    opacity: 0;
  }

  .quick-fade-enter-to, .quick-fade-leave {
    opacity: 1;
  }

  .quick-fade-enter-active, .quick-fade-leave-active {
    transition: opacity .4s ease;
  }

  .quick-fade-enter, .quick-fade-leave-to {
    opacity: 0;
  }

  .slideup-enter-active, .slideup-leave-active {
    transition: transform .4s ease;
  }

  .slideup-enter, .slideup-leave-to {
    transform: translate(0, 100%);
  }

  .slideup-enter-to, .slideup-leave {
    transform: translate(0, 0);
  }

</style>
