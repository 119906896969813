<template>
  <div class="opening-modal">
    <Transition name="slide">
      <img v-if="showLogo" :src="require('@/assets/images/DFCD_logo.svg')" style="width: 300px"/>
    </Transition>
  </div>
</template>

<script>
export default {
  name: 'OpeningModal',
  components: {
  },
  data() {
    return {
      showLogo: false,
    }
  },
  props: {
  },
  created() {
  },
  mounted() {
    this.showLogo = true;
    setTimeout(() => {
      this.showLogo = false;
    }, 2700);
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
  }

}
</script>

<style lang="scss" scoped>

  .slide-enter-active, .slide-leave-active {
    transition: all 1.5s ease;
  }

  .slide-enter {
    transform: translate(0, 50%);
    opacity: 0;
  }
  
  .slide-leave-to {
    transform: translate(0, -50%);
    opacity: 0;
  }

  .slide-enter-to, .slide-leave {
    transform: translate(0, 0);
    opacity: 1;
  }

  .opening-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100dvh;
    height: 100vh;
    background-color: #fff;
    z-index: 9999;

    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 90%;
    }
  }

</style>